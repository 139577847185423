import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default function RightWindow(props){
    return(
        <div style={{height:"100%",width:props.width ?props.width : 500,top:0,right:0,position:"absolute",backgroundColor:"#F3F2F1",zIndex:5}}>
            <div style={{backgroundColor:"#E6E6E6",marginTop:50,paddingRight:10,paddingLeft:10,display:"flex",flexDirection:"row",alignContent:"center",alignItems:"center"}}>
                <h3 style={{color:'#79B550'}}>{props.title}</h3>
                <div style={{flex:1}} />
                <FontAwesomeIcon icon={faWindowClose}style={{width:20,height:20}} onClick={()=>props.closeButton()}/>
            </div>
            <div style={{padding:10}}>
                {props.children}    
            </div>
        </div>
    )
}


/****** 
 * 
 * 
 * 使い方 実装したい画面にて
 * 
 * const [rightMenu,toggleRightMenu] = useState(true);
 * stateを作成、このstateの値で画面の展開を制御する。
 * 
 * {rightMenu ?
 *      <RightWindow closeButton={()=>toggleRightMenu(false)} title={"にゃーん"}>
 *          <p>ばーか</p>
 *      </RightWindow> : null
 * }
 * これを表示したい階層に設置する。引数は以下のとおり
 *   closeButton：右上の閉じるボタンを押した時の動作を配置する。state変更コマンドを配置すればよい。 | arrowFunction
 *   title：一番上、閉じるボタンの隣に表示するタイトル。 ｜ String
 *   内部：下の実際に表示させる内容。 | JSX Object
 * 
 * 
 * ******/