export function postUndefined(data,name,icon){

    console.log(data);
    fetch('https://rc-tech.rintan.net/hooks/RhJkFYwzaoSv5wcBb/q37McuvTPX3LhbHkboWKdt8sb4QSqguvGjN5avHZQoNudiWA', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({text:data, username: name, icon_emoji: icon})
    });
}