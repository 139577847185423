import { useEffect, useState } from "react";
import { MITAI_BORDERLINE, MITAI_SUBTITLETEXT, MITAI_TOPTEXT, MITAI_TEXTBUTTON } from "components/mitoyoAI-style";
import Storage from "@aws-amplify/storage";
import { DataStore } from '@aws-amplify/datastore';
import { JobList, UserStatus } from '../models';
import Lottie from 'react-lottie';
import animationData from 'assets/51690-loading-diamonds.json';
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
};
export function WelcomePage(props){

    const [hoverNum,setHoverNum] = useState(null);
    const [hoverRightButton,setHoverRightButton] = useState(false);
    const [rightMenu,toggleRightMenu] = useState(false);
    const [dataList,setDataList] = useState(undefined);
    const [userList,setUserList] = useState(undefined);

    console.log(props)

    const jobListUpdate = () => DataStore.query(JobList).then(d=>{console.log(d);setDataList(d);})
    const getUserList = async() => await DataStore.query(UserStatus);
    const userListUpdate = () =>{
        getUserList().then(d=>{
            console.log(d);
            if(d.filter(f=>f.userID == props.user.attributes.sub)[0] == undefined){
                DataStore.save(
                    new UserStatus({
                        "userID": props.user.attributes.sub,
                        "state": "register",
                        "userName": props.user.username
                    })
                );
                DataStore.start();
                DataStore.query(UserStatus).then(d=>setUserList(d));
            } 
            else if(d.filter(f=>f.userID == props.user.attributes.sub)[1]){
                DataStore.delete(d.filter(f=>f.userID == props.user.attributes.sub)[0]);
            }
            else{
                setUserList(d);
            }
        })
    }

    useEffect(()=>{
        if(props.user){
            jobListUpdate()
            getUserList()
            DataStore.start()
            DataStore.observe(JobList).subscribe(msg =>jobListUpdate());
            userListUpdate()    
        }
        
    },[])
    return(
        <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
            <div style={{display:"flex",flexDirection:"column",flex:1,padding:10}}>
                <div style={{flex:3}}>
                    <MITAI_TOPTEXT>トップメニュー</MITAI_TOPTEXT>
                    <MITAI_BORDERLINE />
                    <h2>[$Error: Null-Point Exceprion]へようこそ！！</h2>
                    <p>現在のキュー:</p>
                    <div style={{width:"100%",borderColor:"dark",borderWidth:1,borderStyle:"solid"}}>
                        <div><div style={{display:"flex",flexDirection:"row"}}>
                            <h4 style={{flex:3}}>案件名</h4>
                            <h4 style={{flex:1}}>期日</h4>
                            <h4 style={{flex:1}}>担当者</h4>
                            <h4 style={{flex:1}}>ステータス</h4>
                        </div>
                        <div style={{width:"100%",height:1,backgroundColor:"#8A8A8A"}}/>
                            {/* ここから下動的変化 */}
                            {dataList?.filter(f=>f.userID == props.user.attributes.sub).map((data,index)=>{
                                console.log(data);
                                console.log(index);
                                return(
                                    [<div style={{display:"flex",flexDirection:"row", backgroundColor:hoverNum == index ? "#EDEDEC60": undefined}} onMouseEnter={()=>setHoverNum(index)}/*  onMouseLeave={()=>setHoverNum(null)} */ /* onClick={()=>goToNextView(data) }*/>
                                        <p style={{flex:3}}>{data.data.dataName}</p>
                                        <p style={{flex:1}}>{data.data.due}</p>
                                        <p style={{flex:1}}>{userList?.filter(f=>f.userID == data.userID)[0]?.userName || ""}</p>
                                        <p style={{flex:1}}>{data.JobState}</p>
                                    </div>,
                                    <div style={{width:"100%",height:1,backgroundColor:"#8A8A8A"}}/>]
                                )
                            })
                            ||
                            <div>
                                <Lottie options={defaultOptions}height={150}width={150}/>
                            </div>
                            }
                        </div>
                    </div>    
                </div>
                <div style={{flex:2}}>
                    <MITAI_SUBTITLETEXT>おしらせ</MITAI_SUBTITLETEXT>
                    <MITAI_BORDERLINE />
                    <div style={{width:"80%"}}>
                        <div>
                            <p>・このシステムは開発版です。</p> 
                            <p>・ここに通知が来る予定です。</p> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}