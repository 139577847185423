import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faQuestion, faCog } from '@fortawesome/free-solid-svg-icons'
import mitaiLogo from "assets/logo_white.svg"
import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";


import { Menu } from 'components/Menu';
import { WelcomePage } from 'page/WelcomePage';
import { ResultsViewer } from 'page/ResultsViewer';
import { BillingPage } from 'page/Billing';
import { NewUser} from 'page/NewUser';
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignOut, AmplifySignUp } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
Amplify.configure(awsExports);

I18n.putVocabulariesForLanguage("ja", {
  [Translations.CREATE_ACCOUNT_TEXT]: 'アカウントの作成',
  [Translations.EMAIL_LABEL]: 'メールアドレス *',
  [Translations.EMAIL_PLACEHOLDER]: ' ',
  [Translations.FORGOT_PASSWORD_TEXT]: 'パスワードをお忘れの場合',
  [Translations.PASSWORD_LABEL]: 'パスワード *',
  [Translations.PASSWORD_PLACEHOLDER]: 'パスワードを入力してください。',
  [Translations.RESET_PASSWORD_TEXT]: 'パスワードを変更する',
  [Translations.SIGN_IN_ACTION]: 'ログイン',
  [Translations.SIGN_IN_HEADER_TEXT]: 'ログイン',
  [Translations.USERNAME_LABEL]: 'ユーザーID',
  [Translations.USERNAME_PLACEHOLDER]: 'ユーザーIDを入力してください。',
  [Translations.RESET_YOUR_PASSWORD]: 'パスワードをリセット',
  [Translations.BACK_TO_SIGN_IN]: '戻る',
  [Translations.SEND_CODE]: 'コードを送信',
  [Translations.NO_ACCOUNT_TEXT]: 'アカウントがありませんか？',

});

export default function App() {
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();
  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      console.log("ログイン処理")
      console.log(nextAuthState);
      console.log(authData)
      if (nextAuthState === AuthState.SignedIn) {
        setAuthState(nextAuthState);
        setUser(authData)
      } else {
        setAuthState(undefined);
        setUser(undefined)
      }

    });
  }, []);
  const pageContent = [
    { icon: <FontAwesomeIcon icon={faPlay} style={{ width: 30, height: 30 }} />, title: "おしごと", page: <ResultsViewer user={user} /> },
    { icon: <FontAwesomeIcon icon={faQuestion} style={{ width: 30, height: 30 }} />, title: "せいせき(つくりかけ)", page: <BillingPage user={user} /> },
    { icon: <FontAwesomeIcon icon={faQuestion} style={{ width: 30, height: 30 }} />, title: "ヘルプ", page: <NewUser /> },
    { icon: <FontAwesomeIcon icon={faCog} style={{ width: 30, height: 30 }} />, title: "オプション" },
  ]
  const [selectPage, setSelectPage] = useState();
  const signout = () => {
    Amplify.Auth.signOut().catch((err) => console.log(err))
    setAuthState(undefined);
    setUser(undefined)
  }

  return (
    <div className="App" style={{ display: "flex", height: "100vh", width: "100%", flexDirection: "column", overflow: "hidden", }}>
      <div style={{ display: "flex", height: 50, width: "100%", flexDirection: "row", textAlign: "center", alignContent: "center", alignItems: "center", backgroundColor: "#A9D18E", zIndex: 10 }} onClick={() => setSelectPage()}>
        <img src={mitaiLogo} height={30} style={{ padding: 10, marginLeft: 20 }} />
        <div style={{ height: 60, width: 2, margin: 10, backgroundColor: "white" }} />
        <h2 style={{ color: "white" }} >Null-Point : テクノロジー小遣い稼ぎプラットフォーム</h2>
        <div style={{ flex: 1 }} />
        <h2 style={{ color: "white", paddingRight: 10 }} onClick={() =>/* signout() */ { }}>{user && "ようこそ、" + user.username + "さん"}</h2>
        {authState === AuthState.SignedIn && user && <AmplifySignOut buttonText="ログアウト" style={{ backgroundColor: "white" }}></AmplifySignOut>}
      </div>
      {authState === AuthState.SignedIn && user ?
        <div style={{ display: "flex", flex: 1, flexDirection: "row", height: "0vh" }}>
          <Menu pageContent={pageContent} select={(data) => setSelectPage(data)} />
          <div style={{ flex: 1, height: "100%", display: "flex" }} >
            {selectPage || <WelcomePage user={user} />}
          </div>
        </div>
        :
        <AmplifyAuthenticator hideSignUp={true} >
          <AmplifySignIn hideSignUp slot="sign-in" />

          <AmplifySignUp
            slot="sign-up"
            usernameAlias="email"
            formFields={[
              {
                type: "email",
                label: "Email address",
                placeholder: "Enter your email address",
                required: true,
              },
              {
                type: "password",
                label: "Password",
                placeholder: "Enter your password",
                required: true,
              },
            ]}
          ></AmplifySignUp>
        </AmplifyAuthenticator>
      }
    </div>
  );
}


