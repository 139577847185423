import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'

export function Menu(props){
    const [menuToggle,setMenuToggle] = useState(1);
    const [hoverNum,setHoverNum] = useState(null);
    switch(menuToggle){
        case 1:
            return(
                <div style={{width:300,backgroundColor:"#F3F2F1",display:"flex",flexDirection:"column"}}>
                    {props.pageContent.map((d,index)=>{
                        return(
                            <div style={{height:40,display:"flex",flexDirection:"row",padding:10,alignItems:"center",alignContent:"center", backgroundColor:hoverNum == index ? "#D2FFB230": undefined,borderTopRightRadius:100,borderBottomRightRadius:100,boxShadow:hoverNum == index ? "0px 0px 10px #30760050": undefined}} onClick={()=>props.select(d.page)} onMouseEnter={()=>setHoverNum(index)} onMouseLeave={()=>setHoverNum(null)}>
                                <div>{d.icon}</div>
                                <div style={{marginLeft:10}}>{d.title}</div>
                            </div>
                        )
                    })}
                    <div style={{flex:1}} />
                    <div style={{height:40,display:"flex",flexDirection:"row",padding:10,alignItems:"center",alignContent:"center", backgroundColor:hoverNum == 99 ? "#D2FFB230": undefined,borderTopRightRadius:100,borderBottomRightRadius:100,boxShadow:hoverNum == 99 ? "0px 0px 10px #30760050": undefined}} onClick={()=>{setMenuToggle(0)}} onMouseEnter={()=>setHoverNum(99)} onMouseLeave={()=>setHoverNum(null)}>
                        <div><FontAwesomeIcon icon={faArrowLeft}style={{width:30,height:30}}/></div>
                        <div style={{marginLeft:10}}>折り畳む</div>
                    </div>
                </div>
            )
            
        case 0:
            return(
                <div style={{width:50,backgroundColor:"#F3F2F1",display:"flex",flexDirection:"column",alignItems:"center",alignContent:"center"}}>
                    {props.pageContent.map((d,index)=>{
                        return(
                            <div style={{height:40,display:"flex",flexDirection:"row",padding:10,alignItems:"center",alignContent:"center", backgroundColor:hoverNum == index ? "#D2FFB230": undefined,borderTopRightRadius:100,borderBottomRightRadius:100,boxShadow:hoverNum == index ? "0px 0px 10px #30760050": undefined}} onClick={()=>props.select(d.page)} onMouseEnter={()=>setHoverNum(index)} onMouseLeave={()=>setHoverNum(null)}>
                                <div>{d.icon}</div>
                            </div>
                        )
                    })}
                    <div style={{flex:1}} />
                    <div style={{height:40,display:"flex",flexDirection:"row",padding:10,alignItems:"center",alignContent:"center", backgroundColor:hoverNum == 99 ? "#D2FFB230": undefined,borderTopRightRadius:100,borderBottomRightRadius:100,boxShadow:hoverNum == 99 ? "0px 0px 10px #30760050": undefined}} onClick={()=>{setMenuToggle(1)}} onMouseEnter={()=>setHoverNum(99)} onMouseLeave={()=>setHoverNum(null)}>
                        <div><FontAwesomeIcon icon={faArrowRight}style={{width:30,height:30}}/></div>
                    </div>
                </div>
            )
        default:
            return null;
    }
    
}