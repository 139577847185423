import { useEffect, useRef, useState } from "react";
import { MITAI_BORDERLINE, MITAI_TOPTEXT, MITAI_TEXTBUTTON } from "components/mitoyoAI-style";
import RightWindow from "components/RightWindow";
import Storage from "@aws-amplify/storage";
import Lottie from 'react-lottie';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { DataStore } from '@aws-amplify/datastore';
import { JobList, UserStatus } from '../models';
import {postUndefined} from '../util/postToUndefined'
import animationData from 'assets/51690-loading-diamonds.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
};
export function ResultsViewer(props){
    const [hoverNum,setHoverNum] = useState(null);
    const [hoverRightButton,setHoverRightButton] = useState(false);
    const [rightMenu,toggleRightMenu] = useState(false);
    const [dataList,setDataList] = useState(undefined);
    const [userList,setUserList] = useState(undefined);

    const nameRef = useRef();
    const expRef = useRef();
    const addressRef = useRef();
    const jobListUpdate = () => DataStore.query(JobList).then(d=>{console.log(d);setDataList(d);})
    const getUserList = async() => await DataStore.query(UserStatus);
    const userListUpdate = () =>{
        getUserList().then(d=>{
            console.log(d);
            if(d.filter(f=>f.userID == props.user.attributes.sub)[0] == undefined){
                DataStore.save(
                    new UserStatus({
                        "userID": props.user.attributes.sub,
                        "state": "register",
                        "userName": props.user.username
                    })
                );
                DataStore.start();
                DataStore.query(UserStatus).then(d=>setUserList(d));
            } 
            else if(d.filter(f=>f.userID == props.user.attributes.sub)[1]){
                DataStore.delete(d.filter(f=>f.userID == props.user.attributes.sub)[0]);
            }
            else{
                setUserList(d);
            }
        })
    }
    useEffect(()=>{
        jobListUpdate()
        getUserList()
        DataStore.start()
        DataStore.observe(JobList).subscribe(msg =>jobListUpdate());
        userListUpdate()
    },[])

    const StartJob = (data) =>{
        const postData = userList?.filter(f=>f.userID == props.user.attributes.sub)[0]?.userName+ "が"+data.data.dataName+"を受注しました。";
        postUndefined(postData,"ぬるぽおしごと情報",":25_kanade:");
        Storage.get(data.data.url, { 
            download: true 
        }).then(d=>{
            console.log(URL.createObjectURL(d.Body))
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(d.Body);
            downloadLink.download = "data.zip";
            downloadLink.click();
        })
        DataStore.save(JobList.copyOf(data, item => {
            console.log(item)
            item.userID = props.user.attributes.sub;
            item.userState = "作業中";
            item.JobState = "受注済み";
            //item.data = csvText_to_json(data)
        })).then(result=>console.log(result));
        DataStore.start().then(result=>console.log(result));
    }

    const FinishJob = (props) =>{
        const [file,setFile] = useState();
        const [userFileUploadStatus,setUserFileUploadStatus] = useState(undefined);
        const fileUploadRef = useRef();
        const submit = (e) => {
            if(!fileUploadRef?.current.files.length){
                alert("ファイルを指定してください。");
                return null;
            }
            const file = fileUploadRef?.current.files[0];
            try {
                Storage.put(props.data.data.dataName+"/"+props.data.data.due+'/'+file.name, file,{
                resumable: true,
                completeCallback: (event) => {
                    const postData = userList?.filter(f=>f.userID == props.data.userID)[0]?.userName+ "が"+props.data.data.dataName+"を完了させました";
                    postUndefined(postData,"ぬるぽおしごと情報",":mmj_shizuku:");
                    console.log(`Successfully uploaded ${event.key}`);
                    setUserFileUploadStatus("完了")
                    DataStore.save(JobList.copyOf(props.data, item => {
                        item.userState = "作業完了";
                        item.JobState = "確認中";
                        item.data= {
                            "due":props.data.data.due,
                            "dataName":props.data.data.dataName,
                            "url":props.data.data.url,
                            "resultURL":props.data.data.dataName+"/"+props.data.data.due+'/'+file.name
                        }
                    })).then(result=>console.log(result));
                    DataStore.start().then(result=>console.log(result));
                },
                progressCallback: (progress) => {
                    console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                    setUserFileUploadStatus(progress.loaded/progress.total)
                },
                errorCallback: (err) => {
                    console.error('Unexpected error while uploading', err);
                }});
            } catch (error) {
                console.log("Error uploading file: ", error);
            }
        }
        return (
            <div style={{display:"flex",flex:1,width:"100%",height:"100%",flexDirection:"column"}}>
                <div style={{fontSize:20,color:"darkgray",marginTop:8}}>成果物のアップロード</div>
                <MITAI_BORDERLINE />
                <p>成果物のjsonファイルを選択し、アップロードを完了してください。</p>
                {typeof(userFileUploadStatus) == "string" || <input type="file" ref={fileUploadRef}  />}
                <div style={{display:"flex",flexDirection:"row"}}>
                    {typeof(userFileUploadStatus) == "string" || <MITAI_TEXTBUTTON style={{zIndex:10}}  onClick={()=>submit()} >アップロード</MITAI_TEXTBUTTON>}
                </div>
                
                <p>{typeof(userFileUploadStatus) == "string" ?  "アップロードが完了しました！" : userFileUploadStatus != undefined ? (parseInt(userFileUploadStatus*100)+"%") :""} </p>

            </div>
        )
    }

    const CloseJob =(data) =>{
        DataStore.save(JobList.copyOf(data, item => {
            console.log(item)
            item.userState = "作業完了";
            item.JobState = "正常終了";
            //item.data = csvText_to_json(data)
        })).then(result=>console.log(result));
        DataStore.start().then(result=>console.log(result));
    }

    const ManageJob = (props) =>{
        const DownloadFinishedJson = (data) =>{
            console.log(data);
            Storage.get(data.resultURL, { 
                download: true 
            }).then(d=>{
                console.log(URL.createObjectURL(d.Body))
                const downloadLink = document.createElement("a");
                downloadLink.href = URL.createObjectURL(d.Body);
                downloadLink.download = "makeVOTT.json";
                downloadLink.click();
            })
        }
        return(
            <div style={{display:"flex",flex:1,width:"100%",height:"100%",flexDirection:"column"}}>
                <div style={{fontSize:20,color:"darkgray",marginTop:8}}>ステータス</div>
                <MITAI_BORDERLINE />
                <p>JobState: {props.data.JobState}</p>
                <p>userState: {props.data.userState}</p>
                <p>最終更新: {props.data.updatedAt}</p>
                <div style={{display:"flex",flexDirection:"row"}}>
                    {props.data.JobState == "確認中" && <MITAI_TEXTBUTTON style={{zIndex:10,backgroundColor:"red"}} onMouseEnter={()=>setHoverRightButton(true)} onMouseLeave={()=>setHoverRightButton(false)} onClick={()=>{DownloadFinishedJson(props.data.data)} }>jsonファイルのダウンロード</MITAI_TEXTBUTTON>}
                    {props.data.JobState == "確認中" && <MITAI_TEXTBUTTON style={{zIndex:10,backgroundColor:"red"}} onMouseEnter={()=>setHoverRightButton(true)} onMouseLeave={()=>setHoverRightButton(false)} onClick={()=>{CloseJob(props.data)} }>確認完了</MITAI_TEXTBUTTON>}
                    {props.data.JobState == "確認中" && <MITAI_TEXTBUTTON style={{zIndex:10,backgroundColor:"red"}} onMouseEnter={()=>setHoverRightButton(true)} onMouseLeave={()=>setHoverRightButton(false)} onClick={()=>{} }>問題報告</MITAI_TEXTBUTTON>}
                </div>
                <div style={{fontSize:20,color:"darkgray",marginTop:8}}>マスターコントロール</div>
                <MITAI_BORDERLINE />
                <p>する操作を入力してください:</p>
                <input />
                <p style={{color:"darkgray"}} ><i>文章レイアウト ： 「キャンセル」「完了」「募集前」「買収」「人員変更」</i></p>
            </div>
        );
    }
    const MakeNewJob = () =>{
        const [file,setFile] = useState();
        const [userFileUploadStatus,setUserFileUploadStatus] = useState(undefined);
        const fileUploadRef = useRef();
        
        
        const submit = () => {
            if(!fileUploadRef?.current.files.length){
                alert("ファイルを指定してください。");
                return null;
            }
            const file = fileUploadRef?.current.files[0];
            try {
                Storage.put(nameRef?.current.value+"/"+expRef?.current.value+'/'+file.name, file,{
                    resumable: true,
                    completeCallback: (event) => {
                        console.log(`Successfully uploaded ${event.key}`);
                        DataStore.save(
                            new JobList({
                                "userID": undefined,
                                "userState": undefined,
                                "JobState": "募集中",
                                "data": {
                                    "due":expRef?.current.value,
                                    "dataName":nameRef?.current.value,
                                    "url":nameRef?.current.value+"/"+expRef?.current.value+'/'+file.name,
                                    "resultURL":undefined
                                }
                            })
                        );
                        DataStore.start();
                        postUndefined("みんな〜！！わんだほいの時間だよ〜！！\n案件名:"+nameRef?.current.value+"\n期限:"+expRef?.current.value,"[募集!] おしごとが追加されました！！",":ws_emu:");
                        setUserFileUploadStatus("完了")
                    },
                    progressCallback: (progress) => {
                        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                        setUserFileUploadStatus(progress.loaded/progress.total)
                    },
                    errorCallback: (err) => {
                        console.error('Unexpected error while uploading', err);
                    }
                });
            } catch (error) {
                console.log("Error uploading file: ", error);
            }
        }
        
        return(
            <div style={{display:"flex",flex:1,width:"100%",height:"100%",flexDirection:"column"}}>
                <div style={{fontSize:20,color:"darkgray",marginTop:8}}>新規ジョブ作成</div>
                
                <MITAI_BORDERLINE />
                {typeof(userFileUploadStatus) == "string" || [
                    <p>名前</p>,
                    <input ref={nameRef} />,
                    <p>納期</p>,
                    <input ref={expRef}  />,
                    <p>ファイルアップロード</p>,
                    <input type="file" ref={fileUploadRef}  />
                ]}
                <div style={{display:"flex",flexDirection:"row"}}>
                    {typeof(userFileUploadStatus) == "string" || <MITAI_TEXTBUTTON style={{zIndex:10}}  onClick={()=>submit()} >ジョブを作成</MITAI_TEXTBUTTON>}
                </div>
                
                <p>{typeof(userFileUploadStatus) == "string" ?  "アップロードが完了しました！" : userFileUploadStatus != undefined ? (parseInt(userFileUploadStatus*100)+"%") :""} </p>

            </div>
        );
    }


    return(
        <div style={{display:"flex",flexDirection:"row",flex:1}}>
            <div style={{display:"flex",flexDirection:"column",flex:1,padding:10}}>
                <MITAI_TOPTEXT>おしごと</MITAI_TOPTEXT>
                <MITAI_BORDERLINE />
                <div style={{margin:10,display:"flex",flexDirection:"column",flex:1,height:"100%"}}>
                    <div style={{display:"flex",flex:1,flexDirection:"column",height:"100%"}} onMouseEnter={()=>setHoverNum(null)} onMouseLeave={()=>setHoverNum(null)}>
                        <div style={{display:"flex",flexDirection:"row"}}>
                            <h4 style={{flex:3}}>案件名</h4>
                            <h4 style={{flex:1}}>期日</h4>
                            <h4 style={{flex:1}}>担当者</h4>
                            <h4 style={{flex:1}}>ステータス</h4>
                            <h4 style={{flex:1}}></h4>
                        </div>
                        <div style={{width:"100%",height:1,backgroundColor:"#8A8A8A"}}/>
                        <div style={{flex:1,position:"relative"}}>
                            <div style={{overflowY:"auto",height:"calc(100vh - 230px)"}}>
                            {/* ここから下動的変化 */}
                            {[dataList?.map((data,index)=>{
                                return(
                                    [<div style={{display:"flex",flexDirection:"row", backgroundColor:hoverNum == index ? "#EDEDEC60": undefined}} onMouseEnter={()=>setHoverNum(index)}/*  onMouseLeave={()=>setHoverNum(null)} */ /* onClick={()=>goToNextView(data) }*/>
                                        <p style={{flex:3}}>{data.data.dataName}</p>
                                        <p style={{flex:1}}>{data.data.due}</p>
                                        <p style={{flex:1}}>{userList?.filter(f=>f.userID == data.userID)[0]?.userName || ""}</p>
                                        
                                        <div style={{flex:1,alignContent:"center",alignItems:"center",display:"flex"}}>
                                            {hoverNum === index && userList?.filter(f=>f.userID == props.user.attributes.sub)[0].state == "admin" ?
                                                <MITAI_TEXTBUTTON style={{zIndex:10,backgroundColor:"red"}} onMouseEnter={()=>setHoverRightButton(true)} onMouseLeave={()=>setHoverRightButton(false)} onClick={()=>  toggleRightMenu(<ManageJob data={data} />) }>データ管理</MITAI_TEXTBUTTON>
                                            : <p style={{flex:1}}>{data.JobState}</p>}
                                            
                                        </div>
                                        <div style={{flex:1,alignContent:"center",alignItems:"center",display:"flex"}}>
                                            {hoverNum === index && data.JobState != "確認中" && data.JobState != "正常終了" && (data.userID == undefined || data.userID == props.user.attributes.sub) &&
                                                <MITAI_TEXTBUTTON style={{zIndex:10,backgroundColor:"red"}} onMouseEnter={()=>setHoverRightButton(true)} onMouseLeave={()=>setHoverRightButton(false)} onClick={()=> data.userID == props.user.attributes.sub ? toggleRightMenu(<FinishJob data={data} />) : StartJob(data) }>{data.userID == props.user.attributes.sub ? "成果物アップロード":"ダウンロード"}</MITAI_TEXTBUTTON>
                                            }
                                            
                                        </div>
                                    </div>,
                                    <div style={{width:"100%",height:1,backgroundColor:"#8A8A8A"}}/>]
                                )
                            }),
                            userList?.filter(f=>f.userID == props.user.attributes.sub)[0].state == "admin" && 
                            [<div style={{display:"flex",flexDirection:"row", backgroundColor:hoverNum == 99 ? "#EDEDEC60": undefined}} onMouseEnter={()=>setHoverNum(99)}/*  onMouseLeave={()=>setHoverNum(null)} */ onClick={()=>toggleRightMenu(<MakeNewJob />)}>
                                        <p style={{flex:3}}>新規項目の作成 + </p>
                                        <p style={{flex:1}}></p>
                                        <p style={{flex:1}}></p>
                                        
                                        <div style={{flex:1,alignContent:"center",alignItems:"center",display:"flex"}}>
                                            
                                            
                                        </div>
                                        <div style={{flex:1,alignContent:"center",alignItems:"center",display:"flex"}}>
                                            
                                        </div>
                                    </div>,
                                    <div style={{width:"100%",height:1,backgroundColor:"#8A8A8A"}}/>]]
                            ||
                            <div>
                                <Lottie options={defaultOptions}height={150}width={150}/>
                            </div>
                            }
                            </div>
                        </div>
                        {rightMenu &&
                            <RightWindow closeButton={()=>toggleRightMenu(false)} title={"管理パネル"}>
                                {rightMenu}
                            </RightWindow>
                        }    
                    </div>
                </div>
            </div>
        </div>
    )
}
