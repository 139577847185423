import { useEffect, useState } from "react";
import { MITAI_BORDERLINE, MITAI_SUBTITLETEXT, MITAI_TOPTEXT, MITAI_TEXTBUTTON, MITAI_TABBUTTON } from "components/mitoyoAI-style";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ReferenceLine, Brush, Legend } from 'recharts';
import Storage from "@aws-amplify/storage";
import { DataStore } from '@aws-amplify/datastore';
import { JobList, UserStatus } from '../models';
import Lottie from 'react-lottie';
import animationData from 'assets/51690-loading-diamonds.json';
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
};
export function BillingPage(props){

    const [hoverNum,setHoverNum] = useState(null);
    const [hoverRightButton,setHoverRightButton] = useState(false);
    const [rightMenu,toggleRightMenu] = useState(false);
    const [dataList,setDataList] = useState(undefined);
    const [userList,setUserList] = useState(undefined);
    const [windowSize,setWindowSize] = useState(undefined); //ウインドウサイズ取得

    console.log(props)

    const jobListUpdate = () => DataStore.query(JobList).then(d=>{console.log(d);setDataList(d);})
    const getUserList = async() => await DataStore.query(UserStatus);
    const userListUpdate = () =>{
        getUserList().then(d=>{
            console.log(d);
            if(d.filter(f=>f.userID == props.user.attributes.sub)[0] == undefined){
                DataStore.save(
                    new UserStatus({
                        "userID": props.user.attributes.sub,
                        "state": "register",
                        "userName": props.user.username
                    })
                );
                DataStore.start();
                DataStore.query(UserStatus).then(d=>setUserList(d));
            } 
            else if(d.filter(f=>f.userID == props.user.attributes.sub)[1]){
                DataStore.delete(d.filter(f=>f.userID == props.user.attributes.sub)[0]);
            }
            setUserList(d);
        })
    }

    useEffect(()=>{
        if(props.user){
            jobListUpdate()
            getUserList()
            DataStore.start()
            DataStore.observe(JobList).subscribe(msg =>jobListUpdate());
            userListUpdate()
        }
        
    },[])
    //ウインドウサイズ取得
    useEffect(()=>{
        const getWindowSize = () => {
            var w = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0],
            w = w.innerWidth || e.clientWidth || g.clientWidth,
            h = w.innerHeight|| e.clientHeight|| g.clientHeight;

            return {
                    width: w,
                    height: h
            }
        }
        window.addEventListener('resize', () => setWindowSize(getWindowSize()))
        setWindowSize(getWindowSize())
    },[])
    const data = [
        {
          "name": "Page A",
          "uv": 4000,
          "pv": 2400,
          "amt": 2400
        },
        {
          "name": "Page B",
          "uv": 3000,
          "pv": 1398,
          "amt": 2210
        },
        {
          "name": "Page C",
          "uv": 2000,
          "pv": 9800,
          "amt": 2290
        },
        {
          "name": "Page D",
          "uv": 2780,
          "pv": 3908,
          "amt": 2000
        },
        {
          "name": "Page E",
          "uv": 1890,
          "pv": 4800,
          "amt": 2181
        },
        {
          "name": "Page F",
          "uv": 2390,
          "pv": 3800,
          "amt": 2500
        },
        {
          "name": "Page G",
          "uv": 3490,
          "pv": 4300,
          "amt": 2100
        }
      ]
    return(
        <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
            <div style={{display:"flex",flexDirection:"column",padding:10,overflowY:"auto",overflowX:"hidden"}}>
                <div style={{display:"flex", flexDirection:"row"}}>
                    <MITAI_TOPTEXT>{userList && userList.filter(f=>f.userID == props.user.attributes.sub)[0].userName}さんの成績</MITAI_TOPTEXT>
                    <div style={{flex:1}} />
                    <MITAI_TABBUTTON Bcolor={"#A9D18E"} current={false} onClick={()=>{}} >{"<"} 先月(2021/11月期)</MITAI_TABBUTTON>
                    <MITAI_TABBUTTON Bcolor={"#A9D18E"} current={true}  onClick={()=>{}}>今月(2021/12月期)</MITAI_TABBUTTON>
                    <MITAI_TABBUTTON Bcolor={"#A9D18E"} current={false}  onClick={()=>{}}>来月(2021/13月期) {">"}</MITAI_TABBUTTON>
                </div>
                
                <div style={{height:2}}>
                    <MITAI_BORDERLINE />   
                </div>
                <div style={{display:"flex",flexDirection:"row"}}>
                    <div style={{display:"flex",flex:1,flexDirection:"column"}}>
                        <div style={{display:"flex",flex:1,flexDirection:"row"}}>
                            <div style={{flex:1}}>
                                <h2>今月の実績</h2>
                                <h1>{dataList?.filter(f=>f.userID == props.user.attributes.sub).filter(f=>f.JobState == "正常終了").length}件</h1>
                            </div>
                            <div style={{flex:1}}>
                                <h2>今月の予定金額</h2>
                                <h1>1500円</h1>
                            </div>
                        </div>
                        <div style={{display:"flex",flexDirection:"row"}}>
                            <MITAI_TEXTBUTTON>何かがもらえるボタン</MITAI_TEXTBUTTON>
                            <MITAI_TEXTBUTTON>何かがもらえるボタン</MITAI_TEXTBUTTON>
                        </div>
                    </div>
                    
                    <div style={{flex:1}}>
                    <LineChart width={windowSize && windowSize.width / 2} height={250} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="pv" stroke="#8884d8" />
                        <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                    </LineChart>
                    </div>
                </div>
                <p>完了済み案件一覧:</p>
                <div style={{width:"100%",borderColor:"dark",borderWidth:1,borderStyle:"solid"}}>
                    <div><div style={{display:"flex",flexDirection:"row"}}>
                        <h4 style={{flex:3}}>案件名</h4>
                        <h4 style={{flex:1}}>期日</h4>
                        <h4 style={{flex:1}}>担当者</h4>
                        <h4 style={{flex:1}}>ステータス</h4>
                    </div>
                    <div style={{width:"100%",height:1,backgroundColor:"#8A8A8A"}}/>
                        {/* ここから下動的変化 */}
                        {dataList?.filter(f=>f.userID == props.user.attributes.sub).filter(f=>f.JobState == "正常終了").map((data,index)=>{
                            console.log(data);
                            console.log(index);
                            return(
                                [<div style={{display:"flex",flexDirection:"row", backgroundColor:hoverNum == index ? "#EDEDEC60": undefined}} onMouseEnter={()=>setHoverNum(index)}/*  onMouseLeave={()=>setHoverNum(null)} */ /* onClick={()=>goToNextView(data) }*/>
                                    <p style={{flex:3}}>{data.data.dataName}</p>
                                    <p style={{flex:1}}>{data.data.due}</p>
                                    <p style={{flex:1}}>{userList?.filter(f=>f.userID == data.userID)[0]?.userName || ""}</p>
                                    <p style={{flex:1}}>{data.JobState}</p>
                                </div>,
                                <div style={{width:"100%",height:1,backgroundColor:"#8A8A8A"}}/>]
                            )
                        })
                        ||
                        <div>
                            <Lottie options={defaultOptions}height={150}width={150}/>
                        </div>
                        }
                    </div>
                </div>    
            </div>
        </div>
    )
}