export const MITAI_BORDERLINE =()=>{
    return(
        <div style={{width:"100%",height:1,backgroundColor:"#A9D18E"}}/>
    )
}
export const MITAI_TOPTEXT = (props) =>{
    return(
        <div style={{fontSize:30,color:"darkgray"}}>{props.children}</div>
    )
}
export const MITAI_SUBTITLETEXT = (props) =>{
    return(
        <div style={{fontSize:20,color:"darkgray"}}>{props.children}</div>
    )
}

export const MITAI_TEXTBUTTON = (props) =>{
    return(
        <div {...props} style={{backgroundColor:"#A9D18E", color:"white",padding:3,margin:2,fontSize:18,}}>
            {props.children}
        </div>
    )
}
export const MITAI_TABBUTTON = (props) =>{
    const param = props.current ? {
        display:"flex",
        flexDirection:"column",
        color:"white",
        backgroundColor:props.Bcolor,
        fontSize:18,
        paddingLeft:8,
        paddingRight:8,
        marginLeft:5,
        marginRight:5
    } : {
        display:"flex",
        flexDirection:"column",
        color:"white",
        backgroundColor:props.Bcolor+"88",
        fontSize:16,
        paddingLeft:5,
        paddingRight:5,
        marginTop:5,
        marginLeft:5,
        marginRight:5
    }
    return(
        <div style={{...param}} onClick={props.onClick}>
            <div style={{flex:1}} />
            {props.children}
            <div style={{flex:1}} />
        </div>
    )
}