// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { JobList, UserStatus } = initSchema(schema);

export {
  JobList,
  UserStatus
};