import { useEffect, useState } from "react";
import { MITAI_BORDERLINE, MITAI_SUBTITLETEXT, MITAI_TOPTEXT, MITAI_TEXTBUTTON, MITAI_TABBUTTON } from "components/mitoyoAI-style";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ReferenceLine, Brush, Legend, ResponsiveContainer } from 'recharts';
import Storage from "@aws-amplify/storage";
import { DataStore } from '@aws-amplify/datastore';
import { JobList, UserStatus } from '../models';
import Lottie from 'react-lottie';
import animationData from 'assets/51690-loading-diamonds.json';
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
};
export function NewUser(props){

    const [hoverNum,setHoverNum] = useState(null);
    const [hoverRightButton,setHoverRightButton] = useState(false);
    const [rightMenu,toggleRightMenu] = useState(false);
    const [dataList,setDataList] = useState(undefined);
    const [userList,setUserList] = useState(undefined);
    const [windowSize,setWindowSize] = useState(undefined); //ウインドウサイズ取得

    console.log(props)

    const jobListUpdate = () => DataStore.query(JobList).then(d=>{console.log(d);setDataList(d);})
    const getUserList = async() => await DataStore.query(UserStatus);
    const userListUpdate = () =>{
        getUserList().then(d=>{
            console.log(d);
            if(d.filter(f=>f.userID == props.user.attributes.sub)[0] == undefined){
                DataStore.save(
                    new UserStatus({
                        "userID": props.user.attributes.sub,
                        "state": "register",
                        "userName": props.user.username
                    })
                );
                DataStore.start();
                DataStore.query(UserStatus).then(d=>setUserList(d));
            } 
            else if(d.filter(f=>f.userID == props.user.attributes.sub)[1]){
                DataStore.delete(d.filter(f=>f.userID == props.user.attributes.sub)[0]);
            }
            setUserList(d);
        })
    }

    useEffect(()=>{
        if(props.user){
            jobListUpdate()
            getUserList()
            DataStore.start()
            DataStore.observe(JobList).subscribe(msg =>jobListUpdate());
            userListUpdate()
        }
        
    },[])
    //ウインドウサイズ取得
    useEffect(()=>{
        const getWindowSize = () => {
            var w = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0],
            w = w.innerWidth || e.clientWidth || g.clientWidth,
            h = w.innerHeight|| e.clientHeight|| g.clientHeight;

            return {
                    width: w,
                    height: h
            }
        }
        window.addEventListener('resize', () => setWindowSize(getWindowSize()))
        setWindowSize(getWindowSize())
    },[])
    const data = [
        {
          "name": "Page A",
          "uv": 4000,
          "pv": 2400,
          "amt": 2400
        },
        {
          "name": "Page B",
          "uv": 3000,
          "pv": 1398,
          "amt": 2210
        },
        {
          "name": "Page C",
          "uv": 2000,
          "pv": 9800,
          "amt": 2290
        },
        {
          "name": "Page D",
          "uv": 2780,
          "pv": 3908,
          "amt": 2000
        },
        {
          "name": "Page E",
          "uv": 1890,
          "pv": 4800,
          "amt": 2181
        },
        {
          "name": "Page F",
          "uv": 2390,
          "pv": 3800,
          "amt": 2500
        },
        {
          "name": "Page G",
          "uv": 3490,
          "pv": 4300,
          "amt": 2100
        }
      ]
    return(
        <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
            <div style={{display:"flex",flexDirection:"column",padding:10,overflowY:"auto",overflowX:"hidden"}}>
                <div style={{display:"flex", flexDirection:"row"}}>
                    <MITAI_TOPTEXT>Welcome to Null-Point!!</MITAI_TOPTEXT>
                    <div style={{flex:1}} />
                    <MITAI_TABBUTTON Bcolor={"#A9D18E"} current={false} onClick={()=>{}} >{"<"} 先月(2021/11月期)</MITAI_TABBUTTON>
                    <MITAI_TABBUTTON Bcolor={"#A9D18E"} current={true}  onClick={()=>{}}>今月(2021/12月期)</MITAI_TABBUTTON>
                    <MITAI_TABBUTTON Bcolor={"#A9D18E"} current={false}  onClick={()=>{}}>来月(2021/13月期) {">"}</MITAI_TABBUTTON>
                </div>
                <div style={{height:2}}>
                    <MITAI_BORDERLINE />   
                </div>
                <h1>Null-Pointへようこそ！</h1>
                <p>これはテクノロジーのユーザーに提供している三豊AI開発の簡単なバイトシステムです。</p>
                <p>利用にはテクノロジーのユーザー情報の他、会社からの報酬として支払うための情報を提供して頂く必要があります。</p>
                <p>以下のフォームに記入の上、送信ボタンを押すと契約者の確認後契約書が電子署名にてPDF発行されますので諸々の対応が完了次第利用可能となります。</p>
                <p>運用に関する質問はharukinまでDMでよろしくお願いします。</p>
                <div style={{margin:10}}>
                    <p style={{padding:0,margin:0,color:"black"}}>なまえ</p>
                    <div style={{display:"flex",flexDirection:"column",padding:1,backgroundColor:"#ECECEC55",borderRadius:5}}>
                        <input style={{display:"flex",flex:1,borderWidth:0,outlineColor:"blue",outlineWidth:0,padding:10,backgroundColor:"#00000000"}} />
                        <MITAI_BORDERLINE />
                    </div>    
                </div>
                <div style={{margin:10}}>
                    <p style={{padding:0,margin:0,color:"black"}}>電話番号</p>
                    <div style={{display:"flex",flexDirection:"column",padding:1,backgroundColor:"#ECECEC55",borderRadius:5}}>
                        <input style={{display:"flex",flex:1,borderWidth:0,outlineColor:"blue",outlineWidth:0,padding:10,backgroundColor:"#00000000"}} />
                        <MITAI_BORDERLINE />
                    </div>    
                </div>
                <div style={{display:"flex",flexDirection:"row"}}>
                    <div style={{flex:1,margin:10}}>
                        <p style={{padding:0,margin:0,color:"black"}}>郵便番号</p>
                        <div style={{display:"flex",flexDirection:"column",padding:1,backgroundColor:"#ECECEC55",borderRadius:5}}>
                            <input style={{display:"flex",flex:1,borderWidth:0,outlineColor:"blue",outlineWidth:0,padding:10,backgroundColor:"#00000000"}} />
                            <MITAI_BORDERLINE />
                        </div>    
                    </div>
                    <div style={{display:"flex",flexDirection:"column"}}>
                        <div style={{flex:1}} />
                        <p>-</p>  
                    </div>
                    <div style={{flex:1,margin:10}}>
                        <p style={{padding:0,margin:0,color:"white"}}>「」</p>
                        <div style={{display:"flex",flexDirection:"column",padding:1,backgroundColor:"#ECECEC55",borderRadius:5}}>
                            <input style={{display:"flex",flex:1,borderWidth:0,outlineColor:"blue",outlineWidth:0,padding:10,backgroundColor:"#00000000"}} />
                            <MITAI_BORDERLINE />
                        </div>    
                    </div>
                </div>
                <div style={{display:"flex",flexDirection:"row"}}>
                    <div style={{flex:1,margin:10}}>
                        <p style={{padding:0,margin:0,color:"black"}}>都道府県</p>
                        <div style={{display:"flex",flexDirection:"column",padding:1,backgroundColor:"#ECECEC55",borderRadius:5}}>
                            <input style={{display:"flex",flex:1,borderWidth:0,outlineColor:"blue",outlineWidth:0,padding:10,backgroundColor:"#00000000"}} />
                            <MITAI_BORDERLINE />
                        </div>    
                    </div>
                    <div style={{flex:1,margin:10}}>
                        <p style={{padding:0,margin:0,color:"black"}}>市区町村</p>
                        <div style={{display:"flex",flexDirection:"column",padding:1,backgroundColor:"#ECECEC55",borderRadius:5}}>
                            <input style={{display:"flex",flex:1,borderWidth:0,outlineColor:"blue",outlineWidth:0,padding:10,backgroundColor:"#00000000"}} />
                            <MITAI_BORDERLINE />
                        </div>    
                    </div>
                    <div style={{flex:1,margin:10}}>
                        <p style={{padding:0,margin:0,color:"black"}}>番地</p>
                        <div style={{display:"flex",flexDirection:"column",padding:1,backgroundColor:"#ECECEC55",borderRadius:5}}>
                            <input style={{display:"flex",flex:1,borderWidth:0,outlineColor:"blue",outlineWidth:0,padding:10,backgroundColor:"#00000000"}} />
                            <MITAI_BORDERLINE />
                        </div>    
                    </div>
                </div>
                <div style={{flex:1,margin:10}}>
                    <p style={{padding:0,margin:0,color:"black"}}>残りの情報</p>
                    <div style={{display:"flex",flexDirection:"column",padding:1,backgroundColor:"#ECECEC55",borderRadius:5}}>
                        <input style={{display:"flex",flex:1,borderWidth:0,outlineColor:"blue",outlineWidth:0,padding:10,backgroundColor:"#00000000"}} />
                        <MITAI_BORDERLINE />
                    </div>    
                </div>
                <div style={{flex:1,margin:10}}>
                    <p style={{padding:0,margin:0,color:"black"}}>残りの情報</p>
                    <div style={{display:"flex",flexDirection:"column",padding:1,backgroundColor:"#ECECEC55",borderRadius:5}}>
                        <input style={{display:"flex",flex:1,borderWidth:0,outlineColor:"blue",outlineWidth:0,padding:10,backgroundColor:"#00000000"}} />
                        <MITAI_BORDERLINE />
                    </div>    
                </div>
                
                
            </div>
        </div>
    )
}